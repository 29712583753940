

























































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class Parent extends Vue {
  private secondParentVisible = false
  private loading: boolean = false
  private parentList: Array<any> = []

  private get locale(): string {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    this.loading = true
    this.parentList = []
    StudentController.getParents()
      .then(res => {
        this.parentList = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}
