































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'

@Component
export default class Instruction extends Vue {
  private instructionForm: any
  private data: any = {}
  private loading: boolean = false
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  private beforeCreate(): void {
    this.instructionForm = this.$form.createForm(this)
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    this.loading = true
    StudentController.getRemarks()
      .then(res => {
        this.$nextTick(() => {
          this.data = res.data
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}
