



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { StudentController, DropDownController } from '@/services/request.service'

@Component
export default class classInfo extends Vue {
  private data: any = []
  private loading: boolean = false

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYearList: any = []

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'classType',
        key: 'classType',
        title: this.$t('student.classType'),
        customRender: text => {
          return this.$tc(`student.classTypeEnum.${text}`)
        },
      },
      {
        dataIndex: 'classTypeName',
        key: 'classTypeName',
        title: this.$t('student.className'),
      },
      {
        dataIndex: 'teachers',
        key: 'teachers',
        title: this.$t('student.teacher'),
        scopedSlots: { customRender: 'teachers' },
      },
    ]
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYearList = res.data.filter(item => item.extraValue !== '1100')
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.getData()
    })
  }

  private getData(): void {
    this.loading = true
    const studentId = this.$store.state.memberId
    StudentController.classInfo(this.filter.schoolYearId, studentId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private copyEmail(text): void {
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }
}
