


















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DropDownController, TaskGradeController } from '@/services/request.service'
import moment from 'moment'
import { getToken, getSchoolInfo } from '@/utils/utils'
import { saveAs } from 'file-saver'

@Component
export default class KSGrading extends Vue {
  private data: any = []
  private filters: any = {
    termId: undefined,
    schoolYearId: undefined,
  }
  private downloading: boolean = false
  private dynamicHeader: any = []
  private loading: boolean = false
  private reportId: any
  private reportName: any
  private schoolYearList: any = []
  private terms: any = []

  private get columns(): Array<any> {
    let dynamicColumns = []
    dynamicColumns = this.dynamicHeader.map(item => {
      return {
        dataIndex: item.taskId,
        key: item.taskId,
        title: item.taskType,
        scopedSlots: { customRender: `dynamicColumns` },
      }
    })
    return [
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('exam.subject'),
        fixed: dynamicColumns.length > 2 ? 'left' : false,
        ellipsis: true,
      },
      {
        dataIndex: 'course',
        key: 'subjectClass',
        fixed: dynamicColumns.length > 2 ? 'left' : false,
        title: this.$t('common.subjectClass'),
        ellipsis: true,
      },
      ...dynamicColumns,
      {
        dataIndex: 'grade',
        key: 'level',
        fixed: dynamicColumns.length > 2 ? 'right' : false,
        title: this.$t('common.level'),
      },
      {
        dataIndex: 'description',
        key: 'description',
        fixed: dynamicColumns.length > 2 ? 'right' : false,
        ellipsis: true,
        title: this.$t('common.description'),
      },
    ]
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get studentId(): any {
    return this.$store.state.memberId
  }

  private created() {
    this.getSchoolYearList()
  }

  private async download() {
    this.downloading = true
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    const name = this.reportName || '报告'
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportReport/${token}/${schoolInfo.schoolId}/${
      this.reportId
    }/${moment().valueOf()}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true`
    setTimeout(() => {
      saveAs(url, `${name}.pdf`)
    }, 0)
    setTimeout(() => {
      this.downloading = false
    }, 0)
  }

  private getData(): void {
    this.loading = true
    this.data = []
    this.dynamicHeader = []
    this.reportId = undefined
    this.reportName = undefined
    TaskGradeController.getStudentGradeSummary(this.filters.termId)
      .then(res => {
        this.data = (res.data.items || []).map(item => ({
          ...item,
          ...item.examGrades,
        }))
        this.dynamicHeader = res.data.examTasks
        this.reportId = res.data.taskReportId
        this.reportName = res.data.reportName
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.filters.schoolYearId = this.schoolYearList.filter(
        item => item.value.indexOf(moment().format('YYYY')) !== -1
      )[0].key
      this.getTermDropDown(this.filters.schoolYearId)
    })
  }

  private getTermDropDown(schoolYearId): void {
    TaskGradeController.getGradingSemesters(schoolYearId)
      .then(res => {
        this.terms = res.data
        this.filters.termId = (this.terms[0] || {}).semesterId
        if (this.filters.termId) {
          this.getData()
        }
      })
      .catch(err => console.log(err))
  }
}
