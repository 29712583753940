
















































































import { Component, Vue } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'
import Student from './StudentDetail/Student.vue'
import Parent from './StudentDetail/Parent.vue'
// import Curriculum from './StudentDetail/Curriculum.vue'
// import Teacher from './StudentDetail/Teacher.vue'
import ClassInfo from './StudentDetail/ClassInfo.vue'
import Subject from './StudentDetail/Subject.vue'
// import Grading from './StudentDetail/Grading.vue'
// import Report from './StudentDetail/Report.vue'
import Instruction from './StudentDetail/Instruction.vue'
import KSGrading from './StudentDetail/KSGrading.vue'
// import leadership from './StudentDetail/Leadership.vue'
// import Honor from './StudentDetail/Honor.vue'
import GraduationProgress from './StudentDetail/GraduationProgress.vue'
import { getSchoolInfo } from '@/utils/utils'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Student,
    Parent,
    // Curriculum,
    // Teacher,
    ClassInfo,
    Subject,
    // Grading,
    // Report,
    Instruction,
    KSGrading,
    // leadership,
    // Honor,
    GraduationProgress,
  },
})
export default class StudentDetail extends Vue {
  private menuKey = ['student']
  private saveLoading = false
  private canLeave = true
  private info: any = {}
  private students: Array<any> = []
  private schoolId: any = getSchoolInfo().schoolId

  private created(): void {
    this.getStudentBrief()
  }

  private getStudentBrief(): void {
    StudentController.getStudentBriefInfo()
      .then(res => {
        this.info = res.data
        this.$store.commit('setCurriculumStudentInfo', this.info)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private onStatusChange(val): void {
    if (val === 'edit') {
      this.canLeave = false
    } else if (val === 'view') {
      this.canLeave = true
    }
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private changeMenu({ item, key, keyPath }): void {
    if (this.menuKey[0] !== key) {
      if (this.canLeave) {
        this.menuKey = [key]
      } else {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            this.menuKey = [key]
            this.canLeave = true
          },
        })
      }
    }
  }
}
