/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
@Component({})
export default class operationsGroup extends Vue {
  private get showFold(): any {
    return (this.$slots.default || []).length > 3
  }

  public render() {
    let children: any = this.$slots.default || []
    children = children.filter(item => item.data)

    const preference = children.slice(0, this.showFold ? 2 : 3)
    const folded = this.showFold ? children.slice(2) : []
    return (
      <span>
        {preference.map((item, i) => [
          item,
          this.showFold || i + 1 < preference.length ? <a-divider type="vertical" /> : undefined,
        ])}
        {this.showFold ? (
          <a-popover
            placement="topRight"
            trigger="hover"
            scopedSlots={{
              content: props => {
                return folded.map((child, j) => [
                  child,
                  j + 1 < folded.length ? <a-divider type="vertical" /> : undefined,
                ])
              },
            }}>
            <a>{this.$tc('common.moreOperations')}</a>
          </a-popover>
        ) : (
          undefined
        )}
      </span>
    )
  }
}
